<!-- A设备管理-巡检管理-点检工单-修改工单 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">修改工单</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="点检类型：" class=" ">
                    <!-- <el-input v-model="formInline.planStatus" placeholder="审批人" class="width_180"></el-input> -->
                    <el-select v-model="formInline.orderType" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in orderTypeAr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="接单时间：">
                    <el-date-picker type="datetime" placeholder="选择日期时间" v-model="formInline.takeTime" class="width_180"
                        clear-icon="el-input-icon" disabled></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="工单状态：" class=" ">
                    <el-select v-model="formInline.orderStatus" placeholder="请选择" class="width_180">
                        <el-option v-for="item in options" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="工单名称：">
                    <el-input v-model="formInline.orderName" placeholder="请输入" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="点检人：">
                    <el-input v-model="formInline.execPerson" placeholder="请输入" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="点检位置范围：">
                    <el-input v-model="formInline.orderLocScope" placeholder="请输入" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="记录人：">
                    <el-input v-model="formInline.oprPerson" placeholder="请输入" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="执行时间长度：">
                    <el-input v-model="formInline.execLenM" type="number" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="开始时间：">
                    <el-date-picker type="datetime" placeholder="选择日期时间" v-model="formInline.startTime"
                        class="width_180" clear-icon="el-input-icon" disabled></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间：">
                    <el-date-picker type="datetime" placeholder="选择日期时间" v-model="formInline.endTime" class="width_180"
                        clear-icon="el-input-icon" disabled></el-date-picker>
                </el-form-item>
                <el-form-item label="指定结单时间：">
                    <el-date-picker type="datetime" placeholder="选择日期时间" v-model="formInline.assignEndTime"
                        class="width_180" clear-icon="el-input-icon" disabled></el-date-picker>
                </el-form-item>
                <el-form-item label="点检班组：" class=" ">
                    <el-select v-model="formInline.execGroup" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="(item,index) in inspectionGroupAr" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" placeholder="请输入" class="width_180"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="名称：">
                    <el-input v-model="formInline.user" placeholder="请输入" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="巡检时间：">
                    <el-date-picker type="datetime" placeholder="2021-02-04 14:51" v-model="formInline.user"
                        class="width_180"></el-date-picker>
                </el-form-item>
                <el-form-item label="巡检人：">
                    <el-input v-model="formInline.user" placeholder="试用用户" class="width_180"></el-input>
                </el-form-item> -->
                <el-form-item label="是否异常：" class=" ">
                    <el-radio-group v-model="formInline.isAbnormal" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否结单逾期：" class=" ">
                    <el-radio-group v-model="formInline.isEndOverdue" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <el-form :model="formInline" label-width="110px" class="flex flex_wrap " style="width: 65%;">
                 <el-form-item label="备注：">
                    <!-- <el-input v-model="formInline.remarks" placeholder="请输入" class="width_180" readonly></el-input> -->
                    <el-input v-model="formInline.remarks" class="width_1100" type="textarea" :rows="3">
                    </el-input>
                </el-form-item>
                <el-form-item label="逾期原因：">
                    <el-input v-model="formInline.overdueReason" class="width_1100" type="textarea" :rows="3">
                    </el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100">设备信息</div>
            <div class="flex flex_column width_100 pdl30 pdr30">
                <!-- <div class="flex justify_start" style="width: 65%;">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                        style=" background: #03CABE;border: none;">点检记录</el-button>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                        style=" background: #03CABE;border: none;">点检内容</el-button>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                        style=" background: #03CABE;border: none;">点检设备</el-button>
                </div> -->
                <div class="height_270">
                    <!-- <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_10 "
                        @selection-change="handleSelectionChange" height="100%" border style="width: 100%;">
                        <el-table-column prop="name" label="巡检项目" width=""></el-table-column>
                        <el-table-column prop="name" label="合计项" width=""></el-table-column>
                        <el-table-column prop="name" label="漏检数" width=""></el-table-column>
                        <el-table-column prop="name" label="异常数" width=""></el-table-column>
                        <el-table-column prop="name" label="设备编号" width=""></el-table-column>
                        <el-table-column prop="name" label="设备名称" width=""></el-table-column>
                        <el-table-column prop="name" label="规格型号" width=""></el-table-column>
                        <el-table-column label="操作"  width="">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD">
                                    <span style="border-bottom: 1px solid #009BFD;">详细</span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table> -->
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_10 "
                        height="100%" border>
                        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                        <el-table-column prop="inspDeviceID" label="设备编号" width=""></el-table-column>
                        <el-table-column prop="inspDeviceName" label="设备名称" width=""></el-table-column>
                        <el-table-column prop="inspDeviceLocation" label="位置" width=""></el-table-column>
                        <el-table-column prop="remarks" label="备注" width=""></el-table-column>
                        <el-table-column label="点检标准" width="">
                            <template slot-scope="scope">
                                <!-- <el-checkbox v-model="checked">测试</el-checkbox> -->
                                <!-- <el-checkbox prop="checked">测试</el-checkbox> -->
                                <el-button type="text" class="color_009BFD pd_0">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="getStandard(scope.row)">更多</span>
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作"  width="">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pd_0">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="recordStandard(scope.row)">记录</span>
                                </el-button>
                                <!-- <el-button type="text" class="color_009BFD pd_0">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="delStandard(scope.$index)">删除</span>
                                </el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

            </div>
            <!-- table -->
            <!-- <div class="flex justify_between align_center" style="width: 65%;">
                <div class="color_808080">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->

            <div class="flex mg_top_30 mg_bottom_20">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>

        <!-- 点检标准详情 -->
        <el-dialog title="点检标准详情" :visible.sync="inspectionStandardDialog" top='7vh'>
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">点检标准详情</div> -->
            <div class="flex mg_top_6 pdb_10 fn_size14 color_1A1A1A border_bottom_1_E6E6E6">
                <span class="height_30 ">设备名称：{{inspectionStandardInfo.inspDeviceName}}</span>
                <span class="height_30 mg_left_30">设备编号：{{inspectionStandardInfo.inspDeviceID}}</span>
            </div>
            <div class="mg_top_10  fn_size14 color_1A1A1A standardDialog_h">
                <div v-for="(item,index) in inspectionStandardInfoList.inspDeviceContent" :key="index">
                    <div class="flex font_bold mg_top_10">
                        <span>点检标准编号：</span>
                        <span class="color_007DF2">{{item.inspStandardID}}</span>
                    </div>
                    <el-table :data="item.inspStandardDetail" style="width: 100%;" max-height="200" class="mg_top_10">
                        <el-table-column type="index" label="序号" width="70">
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailID" label="点检标准编号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailType" label="点检标准类型" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailContent" label="点检标准内容" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="点检详情标准" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                    v-if="scope.row.inspStandardDetailType == 2">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="lookImg(scope.row.inspStandardDetailStandard)">查看</span>
                                </el-button>
                                <span v-else>{{scope.row.inspStandardDetailStandard}}</span>

                                <!-- <el-input v-model="scope.row.inspStandardDetailRecordStatus"></el-input> -->
                                <!-- <el-radio-group v-model="scope.row.inspStandardDetailRecordStatus">
                                    <el-radio :label="true">是</el-radio>
                                    <el-radio :label="false">否</el-radio>
                                </el-radio-group> -->
                            </template>
                        </el-table-column>
                        <!-- prop="inspStandardDetailRecord"  -->
                        <el-table-column label="点检记录">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                    v-if="scope.row.inspStandardDetailRecord!=null&& scope.row.inspStandardDetailType == 2 ">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="lookImg(scope.row.inspStandardDetailRecord)">查看</span>
                                </el-button>
                                <span v-else>{{scope.row.inspStandardDetailRecord}}</span>
                            </template>
                            <!-- <template slot-scope="scope"> -->
                            <!-- <el-input v-model="scope.row.inspStandardDetailRecord"
                                    v-if="scope.row.inspStandardDetailType==1"></el-input>
                                <el-checkbox v-else-if="scope.row.inspStandardDetailType==0"
                                    v-model="scope.row.inspStandardDetailRecord" true-label='是' false-label="否">
                                </el-checkbox>
                                <el-button type="text" class="color_009BFD pd_0" v-else>
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="addRecord(scope.row)">编辑</span>
                                </el-button> -->
                            <!-- </template> -->
                        </el-table-column>
                        <el-table-column label="是否正常">
                            <template slot-scope="scope">
                                <span>{{scope.row.inspStandardDetailRecordStatus}}</span>
                                <!-- <el-input v-model="scope.row.inspStandardDetailRecordStatus"></el-input> -->
                                <!-- <el-radio-group v-model="scope.row.inspStandardDetailRecordStatus">
                                    <el-radio :label="true">是</el-radio>
                                    <el-radio :label="false">否</el-radio>
                                </el-radio-group> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="flex font_bold mg_top_20">
                    <div>描述</div>
                </div>
                <el-input class="mg_top_10" type="textarea" :rows="4" v-model="inspectionStandardInfoList.remarks"
                    disabled>
                </el-input>
            </div>
        </el-dialog>


        <!-- 记录 点检记录 -->
        <el-dialog title="点检标准详情" :visible.sync="standardRecordDialog" top='7vh' width="60%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">点检标准详情</div> -->
            <div class="flex mg_top_6 pdb_10 fn_size14 color_1A1A1A border_bottom_1_E6E6E6">
                <span class="height_30 ">设备名称：{{inspectionStandardInfo.inspDeviceName}}</span>
                <span class="height_30 mg_left_30">设备编号：{{inspectionStandardInfo.inspDeviceID}}</span>
            </div>
            <div class="mg_top_10  fn_size14 color_1A1A1A standardDialog_h">
                <div v-for="(item,index) in inspectionStandardInfoList.inspDeviceContent" :key="index">
                    <div class="flex font_bold mg_top_10">
                        <span>点检标准编号：</span>
                        <span class="color_007DF2">{{item.inspStandardID}}</span>
                    </div>
                    <el-table :data="item.inspStandardDetail" style="width: 100%;" height="200" class="mg_top_10">
                        <el-table-column type="index" label="序号" width="70">
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailID" label="点检标准编号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailType" label="点检标准类型" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailContent" label="点检标准内容" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="点检详情标准" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                    v-if="scope.row.inspStandardDetailType == 2">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="lookImg(scope.row.inspStandardDetailStandard)">查看</span>
                                </el-button>
                                <span v-else>{{scope.row.inspStandardDetailStandard}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailRecord" label="点检记录">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.inspStandardDetailRecord"
                                    v-if="scope.row.inspStandardDetailType==1"></el-input>
                                <el-checkbox v-else-if="scope.row.inspStandardDetailType==0"
                                    v-model="scope.row.inspStandardDetailRecord" true-label='是' false-label="否">
                                </el-checkbox>
                                <el-button type="text" class="color_009BFD pd_0" v-else>
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="addRecord(scope.row)">编辑</span>
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailRecordStatus" label="是否正常">
                            <template slot-scope="scope">
                                <!-- <el-input v-model="scope.row.inspStandardDetailRecordStatus"></el-input> -->
                                <el-radio-group v-model="scope.row.inspStandardDetailRecordStatus">
                                    <el-radio :label="true">是</el-radio>
                                    <el-radio :label="false">否</el-radio>
                                </el-radio-group>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="flex font_bold mg_top_20">
                    <div>描述</div>
                </div>
                <el-input class="mg_top_10" type="textarea" :rows="4" v-model="inspectionStandardInfoList.remarks"
                    disabled>
                </el-input>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="cancelEditStandard">取 消</el-button>
                <el-button type="primary" @click="confirmEditStandard">确 定</el-button>
            </div>

            <el-dialog title="添加记录" width="30%" :visible.sync="innerVisible" append-to-body>
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加记录</div> -->

                <div class="height_370 mg_top_20">
                    <el-form :model="formStandard" label-width="110px">
                        <!-- 图片型 -->
                        <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspStandardDetailType==2">
                            <el-upload class="avatar-uploader" action="666666" :before-upload="beforeAvatarUpload"
                                :show-file-list="false">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <el-button type="primary" class="mg_top_10" style="background: #2FB060;" @click="uplodeImg">
                                上传
                            </el-button>
                        </el-form-item>
                        <!-- 勾选型 -->
                        <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspStandardDetailType==3">
                            <div class="">
                                <!-- <el-button type="primary" icon="el-icon-plus" class="add_btn"
                                    @click="addInspDetailStandard"></el-button> -->
                                <el-checkbox-group v-model="inspStandardDetailRecordList"
                                    class="flex flex_column align_start detailStandard_h mg_top_10">
                                    <el-checkbox v-for="(item,index) in inspDetailStandardList" :key="index"
                                        :label="item">{{item}}
                                    </el-checkbox>
                                    <!-- <el-checkbox :label="item" v-for="(item,index) in inspDetailStandardList"
                                        :key="index"></el-checkbox> -->
                                    <!-- <el-checkbox :label="item" v-for="(item,index) in inspDetailStandardList"
                                        :key="index">
                                    </el-checkbox> -->
                                </el-checkbox-group>
                            </div>
                        </el-form-item>
                    </el-form>

                    <div class="flex justify_center pdb_20 ">
                        <el-button @click="innerVisible = false">取 消</el-button>
                        <el-button type="primary" @click="confirmAddStandard">确 定</el-button>
                    </div>
                </div>
            </el-dialog>
        </el-dialog>

        <!-- 查看图片 -->
        <el-dialog title="查看图片" :visible.sync="dialogLookImg" width="30%" top="20vh">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">查看图片</div> -->
            <img :src="imgUrl" alt="" class="imgLook">

        </el-dialog>


    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'

    import {
        inspectionGroupQuery, //点检班组筛选
        inspectionOrderTypeQuery, //点检工单类型
        inspectionOrderQuery,

        uploadInspectionImage,
        inspectionLoadImage,
        inspectionOrderUpdate,
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                checked: false,
                options: [],
                tableData: [],
                formInline: {},
                input2: '',

                detailID: '',
                userID: '',
                inspectionGroupAr: [],
                orderTypeAr: [],


                inspectionStandardDialog: false,
                inspectionStandardInfo: {},
                inspectionStandardInfoList: [],


                standardRecordDialog: false,
                innerVisible: false,
                imageUrl: '',
                imgInfo: {
                    fileName: '',
                    format: '',
                    deviceID: '',
                    content: ''
                },
                inspDetailStandardList: [],
                inspStandardDetailStandard: '',


                formStandard: {
                    inspStandardDetailType: '',
                    inspStandardDetailRecord: '',
                },

                inspStandardDetailRecordList: [],
                orderRecordList: [],
                imgUrl: '',
                dialogLookImg: false
            }
        },
        mounted() {
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                // this.maintainOrderQuery();//此处是根据ID查询数据详情
            }
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.inspectionGroupQuery();
            this.inspectionOrderTypeQuery();

            this.inspectionOrderQuery();
        },
        methods: {
            // 点检班组
            async inspectionGroupQuery() {
                const res = await inspectionGroupQuery();
                this.inspectionGroupAr = res.data;
            },
            // 点检工单类型 
            async inspectionOrderTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const res = await inspectionOrderTypeQuery(param);
                this.orderTypeAr = res.rows;
            },
            // 点检工单详情
            async inspectionOrderQuery() {
                var param = {
                    ID: this.detailID
                }
                const res = await inspectionOrderQuery(param);
                this.formInline = res.rows[0];
                console.log('点检设备列表', JSON.parse(this.formInline.orderDevList))
                console.log('点检内容列表', JSON.parse(this.formInline.orderContList))
                console.log('点检记录列表', JSON.parse(this.formInline.orderRecordList));
                this.tableData = JSON.parse(this.formInline.orderDevList).array;
                // this.tableData.push(JSON.parse(this.formInline.orderDevList).array) ;

            },
            getStandard(row) {
                // this
                console.log(row);
                // this.tableData;
                this.inspectionStandardInfo = row;
                this.inspectionStandardInfoList = row.inspDetailsArray;
                this.inspectionStandardDialog = true;
            },
            // 记录 点检记录 
            recordStandard(row) {
                this.standardRecordDialog = true;
                this.inspectionStandardInfo = row;
                this.inspectionStandardInfoList = row.inspDetailsArray;

            },
            addRecord(row) {
                console.log(row)
                this.formStandard = row;
                // if(row.inspStandardDetailType==2) {
                // }
                if (row.inspStandardDetailType == 3) {
                    this.formStandard.inspStandardDetailRecord = '';
                    this.inspStandardDetailStandard = row.inspStandardDetailStandard;
                    this.inspDetailStandardList = row.inspStandardDetailStandard.split(',');
                    // for(var i=0;i<row.inspStandardDetailStandard.split(',').length;i++) {
                    //     var data = {
                    //         standard :''
                    //     }
                    //     data.standard = row.inspStandardDetailStandard.split(',')[i]
                    //     this.inspDetailStandardList.push(data)
                    // }
                    // this.inspDetailStandardList = this.inspStandardDetailStandard.split(',');
                }
                this.innerVisible = true;
            },

            // 添加记录
            confirmAddStandard() {
                console.log(this.formStandard.inspStandardDetailRecord);
                if (this.formStandard.inspStandardDetailType == 3) {
                    this.formStandard.inspStandardDetailRecord = this.inspStandardDetailRecordList.join(',')
                }
                if (this.formStandard.inspStandardDetailType == 2 && this.formStandard.inspStandardDetailRecord ==
                    undefined) {
                    this.$message({
                        message: '请上传图片',
                        type: 'success'
                    });
                    return
                }
                // console.log(this.inspectionStandardInfoList);
                this.orderRecordList.push(this.inspectionStandardInfoList)
                this.innerVisible = false;
                this.inspStandardDetailRecordList = [];
                this.imageUrl = '';
                this.imgInfo = {
                    fileName: '',
                    format: '',
                    deviceID: '',
                    content: ''
                };
            },
            // 记录
            confirmEditStandard() {
                this.standardRecordDialog = false;
                this.orderRecordList.push(this.inspectionStandardInfoList);
                console.log(this.inspectionStandardInfoList);
                this.formStandard = {};
                this.imgInfo = {
                    fileName: '',
                    format: '',
                    deviceID: '',
                    content: ''
                };
                this.imageUrl = '';
            },
            cancelEditStandard() {
                this.standardRecordDialog = false;
                this.formStandard = {};
                this.inspectionStandardInfo = {};

            },

            async lookImg(path) {
                // inspectionLoadImage
                var param = {
                    path: path
                }
                const res = await inspectionLoadImage(param);
                this.imgUrl = 'data:image/' + res.format + ';base64,' + res.content;
                this.dialogLookImg = true
            },

            async uplodeImg() {
                this.imgInfo.deviceID = this.inspectionStandardInfo.inspDeviceID;
                this.imgInfo.content = this.imgInfo.content.split(',').slice(-1)[0];
                const selectRes = await uploadInspectionImage(this.imgInfo);
                if (selectRes.data.result) {
                    this.formStandard.inspStandardDetailRecord = selectRes.data.path;
                    if (selectRes.messageEnable) {
                        this.$message({
                            message: selectRes.message,
                            type: 'success'
                        });
                    }
                } else {
                    if (selectRes.messageEnable) {
                        this.$message.error(selectRes.message);
                    }
                }
            },
            beforeAvatarUpload(file) {
                console.log(file)
                let that = this;
                that.file = file;
                that.fileNameTest = file.name;
                var fileType = file.name.split('.').slice(-1);
                that.imgInfo.fileName = file.name.split('.').slice(0)[0];
                that.imgInfo.format = fileType[0];
                console.log(fileType);

                // const extension = file.name.split('.').slice(-1) == 'pdf';
                // 限制文件大小  小于6M
                var fileSize = file.size / 1024 / 1024;
                if (fileSize > 10) {
                    this.$confirm('文件大小须小于10M。', '提示', {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: 'warning'
                    }).then().catch()
                    this.fileNameTest = '';
                    this.imgInfo.fileName = '';
                    this.blobFile = '';
                    return false
                }
                // if (!extension) {
                //     that.$message.warning('上传模板只能是pdf格式!')
                //     that.fileName = '';
                //     return false
                // }
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    // that.blobFile = reader.result;
                    that.imageUrl = reader.result;
                    that.imgInfo.content = reader.result;
                    // console.log(reader.result)
                };
                return false; // 返回false不会自动上传
            },



            async sbumit() {
                // console.log(this.orderRecordList)
                var param = {
                    array: this.orderRecordList
                }
                console.log(this.tableData)
                var param2 = {
                    array: this.tableData
                }
                // console.log(JSON.stringify(param))
                this.formInline.orderRecordList = JSON.stringify(param);
                this.formInline.orderDevList = JSON.stringify(param2)
                console.log(this.formInline)
                // this.inspectionStandardInfoList
                const selectRes = await inspectionOrderUpdate(this.formInline);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                } else {
                    this.$message.error(selectRes.message);
                }
                console.log(this.formInline)
            },

            colsePage() {
                this.$router.go(-1);
            },

            //table选择
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            changeDevice() {
                this.equipmentLedgerOrganizationSelect();
                this.dialogTableVisible = true;
            },
        }

    }
</script>
<style scoped>
    .standardDialog_h {
        height: 650px;
        overflow-y: auto;
        padding-bottom: 15px;
    }

    .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 200px;
        height: 200px;
        line-height: 200px;
        /* width: 200px;
        height: 200px; */
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .avatar {
        width: 200px;
        height: 200px;
        display: block;
    }

    .standard_h {
        height: 450px;
    }

    .detailStandard_h {
        height: 150px;
        overflow-y: overlay;
    }

    .imgLook {
        width: 300px;
        height: 300px;
        margin: 20px auto;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>